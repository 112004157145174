<template>
  <div class="columns no-margin">
    <div class="column no-padding is-full">
      <div class="card">
        <header class="card-header has-background-primary">
          <p class="card-header-title has-text-light is-uppercase">
            {{ headerText }} AKTIVITAS {{ komponenTitle }}
          </p>
        </header>
        <div class="card-content events-card">
          <b-field class="field-spacer"></b-field>
          <template v-if="errorMap.non_field_errors">
            <p class="help is-danger">{{ errorMap.non_field_errors }}</p>
            <b-field class="field-spacer"></b-field>
          </template>

          <b-field
            :label="komponenTitle"
            class="komponen-field"
            custom-class="is-small is-capitalized"
            :type="{ 'is-danger': errorMap.komponen }"
            :message="errorMap.komponen"
          >
            <generic-autocomplete
              placeholder="Cari sebelum buat baru"
              :apiPath="`/penyakit/${komponenTitle}/`"
              :params="kompParamsUrl"
              v-model="aktKomponen.komponen"
              @input="validateInput('komponen')"
              @typing="onKomponenACTyping"
            ></generic-autocomplete>

            <div class="control">
              <router-link :to="komponenAddTo">
                <b-button class="is-primary">
                  <b-icon icon="plus" />
                </b-button>
              </router-link>
            </div>
            <div :v-show="false">
              <!-- diperlukan agar menjadi addons, terkait permasalahan di method
              b-field.hasAddons dimana kalkulasi jumlah node di slot ini, baru button-nya
              yang terhitung. dan tidak di-watch. kemungkinan terkait race-condition.
              -->
            </div>
          </b-field>

          <b-field
            label="Lokasi"
            custom-class="is-small"
            :type="{ 'is-danger': errorMap.lokasi }"
            :message="errorMap.lokasi"
          >
            <generic-select
              v-if="isLoaded || isCreate"
              v-model="aktKomponen.lokasi"
              apiPath="/perawatan/lokasi/options/"
              :params="{ aktivitas_pk: aktivitasId }"
              @input="validateInput('lokasi')"
            ></generic-select>
          </b-field>

          <b-field
            v-if="isCreate"
            class="file"
            :type="{ 'is-danger': errorMap.foto }"
            :message="errorMap.foto"
          >
            <b-upload
              v-model="fotoMdl"
              @input="resize"
              accept=".jpg, .jpeg, .png"
            >
              <a class="button is-primary">
                <b-icon icon="upload"></b-icon>
                <span>Upload Foto</span>
              </a>
            </b-upload>
            <span class="file-name" v-if="fotoMdl">{{ fotoMdl.name }}</span>
          </b-field>

          <b-field
            label="Catatan"
            custom-class="is-small notrequired"
            :type="{ 'is-danger': errorMap.catatan }"
            :message="errorMap.catatan"
          >
            <b-input
              v-model="aktKomponen.catatan"
              type="textarea"
              @input="validateInput('catatan')"
            ></b-input>
          </b-field>

          <!-- perlu diganti dengan div spacing -->
          <b-field class="field-spacer"></b-field>

          <div class="buttons">
            <b-button
              class="is-primary has-text-weight-semibold"
              @click.stop="cancel"
              >Batal</b-button
            >
            <b-button
              class="is-primary has-text-weight-semibold"
              :disabled="!isValid"
              @click.stop.prevent="saveObject(!isCreate)"
              >Simpan</b-button
            >
          </div>
          <b-loading :is-full-page="false" :active.sync="loading"></b-loading>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config.js";
// import axios from "axios";
import { has } from "lodash";
import { mapState, mapActions } from "vuex";
import createUpdateMixin from "@/apps/core/mixins/createUpdateMixin";
import AktivitasKomponen from "../models/cuAktKomponen.js";
import UploadPhoto from "@/apps/core/modules/uploadPhoto.js";

export default {
  name: "AktivitasKomponenCreateUpdate",
  props: ["headerText", "komponenTitle"],
  mixins: [createUpdateMixin],
  components: {
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  data() {
    this.aktivitasMdl = new AktivitasKomponen(this.komponenTitle);
    this.objectMdl = this.aktivitasMdl; // alias yg digunakan di cuMixin
    this.aktivitasMdl.setApiURL(
      `${APP_CONFIG.baseAPIURL}/aktivitas/${this.komponenTitle}/`
    );
    let obv = this.aktivitasMdl.getObservables();
    obv.komponenInput = "";
    obv.foto = null;
    obv.fotoMdl = null;
    const dirname = `aktivitas${this.komponenTitle.toLowerCase()}`;
    this.uploadPhoto = new UploadPhoto(dirname);
    return obv;
  },
  computed: {
    ...mapState("aktivitas", { aktKomponenState: "aktKomponen" }),
    kompParamsUrl() {
      if (this.subDeptId) return { sub_departemen: this.subDeptId };
      return {};
      // let url = `/penyakit/${this.komponenTitle}/`;
      // if (this.subDeptId == undefined) {
      //   return url;
      // }
      // return `${url}?sub_departemen=${this.subDeptId}`;
    },
    aktivitasId() {
      if (has(this.$route.params, "aktivitasId"))
        return this.$route.params.aktivitasId;
      return this.aktKomponen.aktivitas.id;
    },
    isCreate() {
      return has(this.$route.params, "aktivitasId");
    },
    subDeptId() {
      if (this.isCreate) {
        return this.$route.params.subDeptId;
      } else {
        return this.aktKomponen.aktivitas.sub_departemen.id;
      }
    },
    komponenAddTo() {
      const aktKomponen = JSON.parse(JSON.stringify(this.aktKomponen));
      if (!this.isCreate) {
        aktKomponen["edited"] = this.aktivitasMdl.getEdited();
      }
      let namedRoute = `${this.komponenTitle}-add`;
      const params = { aktKomponen: aktKomponen, id: this.subDeptId };
      if (
        ["keterampilan", "diagnosis"].includes(this.komponenTitle) &&
        this.subDeptId != undefined
      ) {
        namedRoute = "subdept-" + namedRoute;
      }
      return { name: namedRoute, params: params };
    },
  },
  methods: {
    ...mapActions("aktivitas", ["setAktKomponen"]),
    onKomponenACTyping(value) {
      this.komponenInput = value;
    },
    fetchData() {
      if (this.isCreate) {
        this.aktivitasMdl.addPayloadData({
          aktivitas: this.$route.params.aktivitasId,
        });
      } else {
        this.aktivitasMdl.setRequiredFields(["komponen", "lokasi"]);
        delete this.errorMap["foto"];
      }

      if (this.aktKomponenState) {
        this.aktKomponen = JSON.parse(JSON.stringify(this.aktKomponenState));
        if (this.isCreate) {
          // this.aktKomponen.aktivitas = this.$route.params.aktivitasId;
          this.aktKomponen.foto = null;
          this.uploadPhoto.requestAWS();
        } else {
          this.setEdited(this.aktKomponen["edited"]);
        }

        for (const field of this.aktivitasMdl.getRequiredFields()) {
          if (this.aktKomponen[field]) {
            this.validateInput(field);
          }
        }
        this.setAktKomponen(null);
      } else if (!this.isCreate) {
        this.objectMdl.load(this.$route.params.id);
      } else {
        // this.aktKomponen.aktivitas = this.$route.params.aktivitasId;
        this.aktKomponen.foto = null;
        this.uploadPhoto.requestAWS();
      }
    },
    resize(foto) {
      this.uploadPhoto.resize(foto, (foto, url) => {
        this.foto = foto;
        this.aktKomponen.foto = url;
        this.validateInput("foto");
      });
    },
    upload() {
      this.uploadPhoto.upload(this.foto, this.saveObject, () => {
        this.aktKomponen.foto = null;
        this.foto = null;
        this.fotoMdl = null;
      });
    },
    saveObject(commit) {
      this.objectMdl.setCommit(commit);
      if (commit) {
        this.objectMdl.save(this.saveContext, () => {
          this.$router.go(-1);
        });
      } else {
        this.objectMdl.save(this.saveContext, this.upload);
      }
    },
  },
  watch: {
    aktKomponen: {
      // aktivitas berubah, update edited
      handler(newValue, oldValue) {
        if (!this.aktivitasMdl.getEdited()) {
          this.setEdited(this.isCreate || !!oldValue.id);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .komponen-field {
  button {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  p.help {
    display: none;
  }
}
</style>

import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "aktKomponen";
const modelPath = "/aktivitas/";

class AktivitasKomponen extends ModelBase {
  constructor(kompContext) {
    let model = {
      id: null,
      aktivitas: {id: null, jenis_keterampilan: "", sub_departemen: {id: null, nama: ""}},
      foto: null,
      komponen: null,
      lokasi: null,
      catatan: ""
    };
    let requiredFields = ["komponen", "lokasi"];
    let nonRequiredFields = ["foto", "catatan"];
    super(modelName, model, requiredFields, nonRequiredFields, modelPath);
    this.kompContext = kompContext;
  }

  // getErrorMap() {
  //   let errorMap = super.getErrorMap();
  //   errorMap.catatan = "";
  //   return errorMap;
  // }

  // validateNonReqField() {
  //   this.observables.errorMap.catatan = "";
  //   this.calcValidity();
  // }

  setKomponenContext(kompContext) {
    this.kompContext = kompContext;
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.komponen = tdata[this.kompContext].id;
    tdata.lokasi = tdata.lokasi? tdata.lokasi.id: null;
    return tdata;
  }

  getPayload() {
    let data = super.getPayload();
    data[this.kompContext] = data.komponen;
    delete data["komponen"];
    return data;
  }
}

export default AktivitasKomponen;
